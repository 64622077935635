import { apiHeader } from "./BaseUrl";

// Auth - Login
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Logout
export const logoutUser = async () => {
  const refresh_token = localStorage.getItem('refresh_token') || {};
  let data;
  await apiHeader
    .post("/auth/logout", {
      refresh_token: refresh_token,
      device_token: "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Forgot Password
export const forgetPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
      password: values.password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const changePassword = async (values) => {
  let data;
  await apiHeader
    .post("/user/password/reset", {
      current_password: values.currentpassword,
      new_password: values.confirmpassword,
      confirm_password:values.newpassword
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Auth Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Reset Password Page Redirection Api
export const resetPasswordPage = async (token) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/verify/email", {
      forgot_password_token:token
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// Auth - Reset Password Api
export const resetPassword = async (values, token, apiData) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd/reset", {
      email: apiData.email,
      forgot_pwd_token: token,
      new_password: values.newpassword,
      confirm_new_password: values.confirmpassword,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Site Setting
export const getSiteSettings = async (type) => {
  let data;
  await apiHeader
    .get(`/settings/?type=${type}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Site Setting Update
export const updateSiteSettings = async (values) => {
  let data;
  await apiHeader
    .put("/settings/site-settings", {
      phone: values.phone.number,
      slug: values.phone.slug,
      country_code: values.phone.code,
      email: values.email,
      address: values.address,
      facebook: values.facebook,
      linkedin: values.linkedin,
      instagram: values.instagram,
      twitter: values.twitter,
      google_play:values.googlelink,
      app_store: values.appstorelink

    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Site Post rate Setting Update
export const updatePostRateSettings = async (values) => {
  let data;
  await apiHeader
    .put("/settings/post-rate", {
      post_rate: values.individual_user_post_rate,
      commission_rate_individual:values.individual_user_commission_rate,
      subscription_rate: values.business_user_post_rate,
      number_of_posts: values.num_of_posts,
      commission_rate: values.commission_rate,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


//Notification
export const notificationUpdate = async (values) => {
  let data;
  await apiHeader
    .post(`/notifications`, {
      notify_type: values?.notification_type,
      subject: values?.notification_subject,
      message: values?.notification_details,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      console.log(err.response);
      if (err) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// User Profile Get Data 
export const getProfile = async () => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// User Profile Edit Data
export const editProfile = async (values,imageURL) => {
  let data;
  await apiHeader
    .put("/user/profile", {
      first_name: values.firstname,
      last_name: values.lastname,
      slug: values.phone.slug,
      code: values.phone.code,
      phone_number: values.phone.number,
      profile_image:imageURL,
      email:values?.email
    })
    .then((res) => {

      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
    return data;
};

// User Profile Edit Photo Only
export const updateProfilePicture = async (image) => {
  let data;
  const apiUrl = "/user/img-upload";
  const formData = new FormData();
  formData.append("img", image);
  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }
  return data;
};


//CMS Get
export const cmsGet = async (type, limit, offset, search) => {
  let data;
  await apiHeader
    // .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .get(`cms/${type}?search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS Create 
export const cmsCreate = async (type,values) => {
  let data;
  await apiHeader
    .post(`cms/${type}`, {
      title: values.title,
      description: values.description,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS Update
export const cmsUpdate = async (type,values,id) => {
  console.log(values)
  let data;
  await apiHeader
    .put(`/cms/${type}/${id}`, {
      title: values.title,
      description: values.description,
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS Delete
export const cmsDelete = async (type,id) => {
  let data;
  await apiHeader
    .delete(`/cms/${type}/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//CMS Status Update
export const cmsStatusUpdate = async (type,id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/cms/${type}/${id}/status`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};
// CMS Swap
export const cmsSwap = async (type,new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/cms/sort-order/${type}`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

///Banner Get
export const bannerGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/banners?search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Banner Create
export const bannerCreate = async (values, imageResponseWeb, imageResponse) => {
  const requestData = {
    name: values.name,
    video_url: values.videoUrl,
    banner_type: values.banner_type,
    image_for_mobile: imageResponse || "",
    image_for_web: imageResponseWeb || "",
    image_url_for_mobile: values.imageUrlmobile || "",
    image_url_for_web: values.imageUrlWeb || "",
  };

  let data;

  await apiHeader
    .post(`banners`, requestData)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });

  return data;
};


//Banner Update
export const bannerUpdate = async (values,imageResponseWeb,imageResponse,id) => {
  const requestData = {
    name: values.name,
    video_url: values.videoUrl,
    banner_type: values.banner_type,
    image_for_mobile: imageResponse || "",
    image_for_web: imageResponseWeb || "",
    image_url_for_mobile: values.imageUrlmobile || "",
    image_url_for_web: values.imageUrlWeb || "",
  };
  
  let data;
  await apiHeader
    .put(`banners/${id}`, requestData
    ).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Banner Delete
export const bannerDelete = async (id) => {
  let data;
  await apiHeader
    .delete(`/banners/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Banner Status Update
export const bannerStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/banners/${id}/status`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//Banner Swap
export const bannerSwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/banners/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//All Product
export const products = async (limit, offset, filter, title, category, lessor, userType, rental ) => {
  let data;
  let queryParams = `?filter=${filter}&limit=${limit}&offset=${offset}`;
  if (title) queryParams += `&title=${title}`;
  if (category) queryParams += `&category=${category}`;
  if (lessor) queryParams += `&lessor=${lessor}`;
  if (userType) queryParams += `&type=${userType}`;
  if (rental) queryParams += `&rental=${rental}`;

  await apiHeader
    .get(`/products${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//Product Status Update
export const productStatusUpdate = async (values, id) => {
  let data;
  await apiHeader
   .patch(`/products/${id}`, {
    status: values.status,
    message: values.message,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


//All Users & Pending Users
export const users = async (limit, offset, searchText, searchType, userType, status,activeUserStatus,startDate,endDate) => {
  let data;
  let queryParams = `?verified=${status}&active=${activeUserStatus?activeUserStatus:"0"}&limit=${limit}&offset=${offset}`;
  if (searchText) queryParams += `&search=${searchText}&type=${searchType || ''}`;
  if (userType) queryParams += `&user_type=${userType}`;
  if(startDate) queryParams += `&start_date=${startDate}`;
  if(endDate) queryParams += `&end_date=${endDate}`;
  await apiHeader
    .get(`/user${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


//Bookings
export const bookings = async (limit, offset, type ) => {
 
  let data;
  await apiHeader
    .get(`/bookings?status=${type}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//User Account Approval
export const userAccountApproval = async (values) => {
  let data;
  await apiHeader
   .patch(`user/approval`, {
    email: values.email,
    status: values.status,
    message: values.message,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//User Status Update
export const userStatusApproval = async (email, status) => {
  let data;
  await apiHeader
   .patch(`user/status`, {
    email: email,
    status: status,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


//Pending/Approved/Declined Payouts 
export const payouts = async (limit, offset, search, type) => {
  let data;
  await apiHeader
    .get(`/payouts?type=${type}&limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const payments = async (limit, offset, search, type) => {
  let data;
  await apiHeader
    .get(`/payment?limit=${limit}&offset=${offset}&status=${type}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


export const paymentsExport = async (startDate,endDate) => {
  let data;
  await apiHeader
    .get(`/payment/export?from=${startDate}&to=${endDate}`)
    
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};
//Pending Payouts Approval
export const payoutApproval = async (values, id) => {
  let data;
  await apiHeader
   .patch(`/payouts/${id}/status`, {
    transaction_ref_id: values.transaction_ref_id,
    remarks: values.remarks,
    status: values.status,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//Categories Main Get
export const categoriesParentGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    // .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .get(`categories/parent?limit=${limit}&offset=${offset}&search=${search?search:""}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Categories Main Create
export const categoriesParentCreate = async (radioValue,values) => {
  let data;

  try {
    const res = await apiHeader.post('categories',{
      parent_id:values?.parent_id ? values?.parent_id : "",
      img:radioValue,
      title:values?.title

    });
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

export const categoriesUpdate = async (radioValue,values,id,parentId) => {
  let data;
  try {
    const res = await apiHeader.put(`categories/${id}`,{
      parent_id:parentId?parentId:"",
      img:radioValue,
      title:values?.title

    });
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

export const categoriesChildtGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    // .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .get(`categories/child?limit=${limit}&offset=${offset}&search=${search?search:""}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const categoriesSubChildtGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`categories/sub-child?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

//Category Delete
export const categoryDelete = async (id) => {
  let data;
  await apiHeader
    .delete(`/categories/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const categorySwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/categories/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
// Pending Product - Get Parent Categories Listing for Dropdown
export const getParentCatList = async (limit) => {
  let data;
  await apiHeader
    .get(`/categories/parent?limit=${limit}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

export const dashboardStates = async () => {
  let data;
  await apiHeader
    .get(`/dashboard/stats`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};