/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import React, { useState } from "react";
import { Upload, message, Image } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};

const ImageUploader = ({ imageUrl, handleChange, form, page }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const multiImage = page == "addPainting" ? true : false;

  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }
  };
  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file && info.file.originFileObj instanceof Blob) {
      getBase64(info.file.originFileObj, (base64) => {
        setLoadingImg(false);
        handleChange(info.file.originFileObj, base64);
      });
    } else {
      setLoadingImg(false);
      handleChange(null, null);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  // console.log(imageUrl)
  return (
    <>
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={multiImage}
        multiple={multiImage}
        maxCount={multiImage ? 10 : 1}
        accept=".jpg, .jpeg, .png"
        beforeUpload={beforeUpload}
        onChange={handleImageChange}
        customRequest={() => {}}
      >
        {!multiImage && imageUrl ? (
          <img src={imageUrl} alt="image" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

// Cover And Multi Image Preview
const ImagePreview = ({ imageUrl, baseURL }) => {
  return (
    <div className="d-flex align-items-center">
      {Array.isArray(imageUrl) ? (
        imageUrl?.map((data, i) => {
          return (
            <div className="multi-image-preview">
              <Image key={i} src={baseURL + data} />
            </div>
          );
        })
      ) : (
        <div className="multi-image-preview">
          <Image src={imageUrl} />
        </div>
      )}
    </div>
  );
};

// Get User Data From Local Storage
const userData = () => {
  const user_data = JSON.parse(localStorage.getItem("user_data")) || {};
  return user_data;
};

// Get Remember Email for LogIn
const remEmail = (form, setRemMe) => {
  const remMe = localStorage.getItem("rememberedEmail");
  if (remMe) {
    form.setFieldsValue({
      email: remMe,
    });
    setRemMe(true);
  }
};

// Multi Search Switching - Dropdown - Pending Registration and All Users
const getPlaceholderText = (searchType) => {
  switch (searchType) {
    case "username":
      return "Username";
    case "email":
      return "Email";
    default:
      return "Phone";
  }
};

// Multi Search Switching - Dropdown - Pending Registration and All Users
const getPlaceholderType = (searchType) => {
  switch (searchType) {
    case "username":
      return "text";
    case "email":
      return "email";
    default:
      return "text";
  }
};


// Clear Local Storage
const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user_data");
  localStorage.removeItem("logedIn");
}


// Validation function for non-negative and non-empty values
const valNonNegativeNum = (_, value) => {
  if (value === undefined || value === null || value === "") {
    return Promise.reject(new Error("You cannot leave this field blank"));
  }
  if (value < 0) {
    return Promise.reject(new Error("Cannot be a negative number"));
  }
  return Promise.resolve();
};
const validateEditorContent = (_, value) => {
  const maxLength = 5000;
  if (value && value.trim() !== "" && value !== "<p><br></p>") {
    if (value.length <= maxLength) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error(`Can not be more than ${maxLength} characters.`));
    }
  }
  return Promise.reject(new Error("You cannot leave this field blank"));
};

export {
  ellipsis,
  idGenerator,
  ImageUploader,
  ImagePreview,
  remEmail,
  userData,
  getPlaceholderText,
  getPlaceholderType,
  clearLocalStorage,
  valNonNegativeNum,
  validateEditorContent
};